import React from "react";
import ProductSection from "../PageSections/ProductsSection";
import ProductSection1 from "../PageSections/ProductsSection1";

export default function ProductsPage () {
    return (
        <>
            <div className="sm:px-16 px-6 flex justify-center items-start pt-16 md:mt-[20px] mt-[35px]">
                <ProductSection />
            </div>
            <div className="sm:px-16 px-6 flex justify-center items-start">
                <ProductSection1 />
            </div>
        </>
    )
}