import React from "react";
import AboutSection from "../PageSections/AboutSection";
import AboutSection1 from "../PageSections/AboutSection1";
import AllImages from "../Images";
import AboutSection2 from "../PageSections/AboutSection2";

export default function AboutUsPage() {
    const [slideDetails,setSlideDetails] = React.useState({
        autoSlide: false,
        autoSlideInterval: 4000
    })
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    return (
        <>
            <div className="sm:px-16 px-6 flex justify-center items-start pt-16 md:mt-[20px] mt-[35px]">
                <div className="xl:max-w-[1280px] w-full">
                    <AboutSection />
                </div>
            </div>
            <div className="sm:px-16 px-6 flex justify-center items-start">
                <div className="xl:max-w-[1280px] w-full">
                    <AboutSection1 />
                </div>
            </div>
            <div className="sm:px-16 px-6 flex justify-center items-start sm:py-12 py-6">
                <div className="xl:max-w-[1280px] w-full "> 
                    <AboutSection2 slideDetails = {slideDetails} 
                    setDetails = {setSlideDetails}
                    currentImageIndex = {currentImageIndex}
                    setCurrentImageIndex = {setCurrentImageIndex}
                    />
                </div>
            </div>
        </>
    )
}