import React from "react";
import { our_services, home_services } from "../../constants";
import { Images } from "../../constants";


export default function ServicesSection1() {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px] ">Our Services</h3>
                {
                    our_services.map((service, index) => (
                        <h2 key = {service.id} className="font-poppins font-bold md:text-[40px] xs:text-[35px] text-[25px]">{service.catchphrase}</h2>
                    ))
                }
                <div className="">
                    {
                        our_services.map((service, index) => (
                            service.statement.map((item, index) => (
                                <div className={`bg-green-100 rounded-md p-4 ${index != service.length-1 && "mt-4"}`}>
                                    <h3>{item.message}</h3>
                                </div>
                            ))
                        ))
                    }
                </div>
            </div>
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src = {Images[5].img} alt="cat" className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]" />
            </div>
        </div>
    )
}