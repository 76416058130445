import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";
import {w1,w2,w3,w4,w5,w6,w7,hand_holding, pawhand, pvet ,cat, chicks, hen, pet_surgery, pup, Gicheha, cow1, cow2, pruner1, pruner2, pruner3, pruner4, pruner7, pruner8, pruner9, pruner10} from "../assets"  
import * as RiIcons from "react-icons/ri"
import * as MdIcons from "react-icons/md"
import * as HiIcons from "react-icons/hi"
import * as AiIcons from "react-icons/ai"

export const navLinks = [
    {
      id: "home",
      title: "Home",
      link : "/"
    },
    {
      id: "about-us",
      title: "About Us",
      link : "/about",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subnav: [
        {
          title: "Mission",
        },
        {
          title: "Vision"
        },
        {
          title: "Core Values"
        }
      ]
    },
    {
      id: "products",
      title: "Our Products",
      link : "/products"
    },
    {
      id: "services",
      title: "Our Services",
      link : "/services"
    },
    {
      id: "team",
      title: "Our Team",
      link : "/team"
    },
    {
        id: "contact",
        title: "Contact Us",
        link : "/contact",
        number: "+254765204349"
    },
  ];

export const contact = [
  {
    id: "contact",
    title: "Contact Us",
    number: "+254765204349"
  }
]

export const newContact = [
  {
    id: "email",
    title: "prunervet@gmail.com",
    icon: <HiIcons.HiOutlineMail style={{color: "white"}} className="md:w-[30px] md:h-[30px] w-[20px] h-[20px]" />
  },
  {
    id: "location",
    title: (
      <>
      Juja Shangwe Place, Muchatha, Juja, Kiambu
      <br />
      P. O. Box 708-01001, Kalimoni
      </>
    ),
    icon: <MdIcons.MdOutlineLocationOn style={{color: "white"}} className="md:w-[30px] md:h-[30px] w-[20px] h-[20px]" />
  }
]

export const socialMedia = [
    {
        id: "social-media-1",
        icon: instagram,
        link: "https://www.instagram.com/pruner_the_vet/",
    },
    {
        id: "social-media-2",
        icon: facebook,
        link: "https://www.facebook.com/",
    },
    {
        id: "social-media-3",
        icon: <AiIcons.AiFillYoutube />,
        link: "https://www.youtube.com/",
    },
    // {
    //     id: "social-media-4",
    //     icon: linkedin,
    //     link: "https://www.linkedin.com/",
    // },
];

export const Images = [
  {
    id: 0,
    img : pvet
  },
  {
    id: 1,
    img: cow1,
    words: (
      <>
        Welcome To
        <br />
        <p className="md:text-[50px] text-[40px]">Prunervet Centre</p>
        Ltd
      </>
    )
  },
  {
    id: 2,
    img: chicks,
    words: "We improve farm and livestock productivity by providing affordable solutions through optimal quality products, services and client support"
  },
  {
    id: 3,
    img: hen,
    words: "Creating food affluent societies"
  },
  {
    id: 4,
    img: cow2,
    words: "We are aiming at satisfaction and fulfilment to our esteemed customers"
  },
  {
    id: 5,
    img: pup,
    words: "To generate long term relationships with you"
  },
  {
    id: 6,
    img: cat,
  },
]

export const newImages = [
  {
    id: 1,
    img: pruner1,
  },
  {
    id: 2,
    img: w1
  },
  {
    id: 4,
    img: w2,
  },
  {
    id: 5,
    img: w5,
  },
  {
    id: 6,
    img: w6,
  },

  {
    id: 7,
    img: pruner9,
  },
  {
    id: 8,
    img: w4,
  }
]

export const newImages2 = [
  {
    id: 1,
    img: pawhand
  }
]

export const Work_Force = [
  {
    id: 1,
    img: Gicheha,
    words: "Mr. Gicheha"
  }
]

export const About = [
  {
    id: 1,
    header:"Mission",
    statement: "To improve farm and livestock productivity by providing affordable solutions through optimal quality products, services and client support."
  },
  {
    id : 2,
    header : "Vision",
    statement: "To create food affluent societies."
  },
  {
    id: 3,
    header: "Core Values",
    values: [
      {
        id: 1,
        value: "Customer Focus",
        aim: "Satisfaction and fulfilment"
      },
      {
        id: 2,
        value: "Essence",
        aim: "Indispensable quality"
      },
      {
        id: 3,
        value: "Integrity",
        aim: "Consistency and uncompromising"
      },
      {
        id: 4,
        value: "Flexibility",
        aim: "Dynamism and willingness to change"
      }      
    ]
  },
  {
    id: 4,
    header: "Objective",
    statement1: `Established in 2015 in Juja Sub County, Kenya, Prunervet Center is committed to delivering affordable and fair-priced veterinary services, products, and support to our clients. 
    By ensuring operational efficiency and targeting specific market segments - including agrovets, individual farmers, veterinary professionals, and walk-in customers - we can offer our services at affordable prices. 
    This focused approach further enhances our efficiency, helping us sidestep cash flow issues often linked to credit payments.`,
    statement: `Prunervet Center is committed to delivering affordable and fair-priced products and veterinary services while offering our clients optimal support in their agribusinesses. 
    Our commerce model covers B2B (Business to business), B2C (Business to Consumer) and DTC (Direct to consumer). Our primary market segments include agrovets (veterinary pharmacies), individual farmers, corporate farms, veterinary professionals, and walk-in customers to whom we offer our products and services at affordable prices and rates. 
    This focused approach enhances our efficiency and optimal support to our customers.`
  }
]

export const our_services = [
  {
    id: "Products",
    catchphrase: "Your One-Stop Hub for Comprehensive Veterinary Solutions",
    statement: [
      {
        message: 
        <>
        <span className="font-semibold">Prunervet Centre Limited</span> proudly offers a comprehensive suite of <span className="font-semibold">veterinary products and services</span>. 
        Our commitment to continuous development and ambition to remain at the forefront of the veterinary field allows us to meet the diverse needs of our clients. 
        We leverage our extensive network of suppliers and veterinary partners to ensure the highest quality solutions and services are always within our clients' reach. 
        Striving to be an indispensable resource, we persistently innovate, making Prunervet Centre the ideal choice for all veterinary demands.
        </>
      },
    ]
  }
]

export const home_services = [
  {
    id: "Home_Services",
    statement: [
      {
        id: 1,
        words: `Prunervet Centre is your comprehensive solution for veterinary services, offering a wide range of products from our extensive supplier and partner networks.`
      },
      {
        id: 2,
        words: `Our focus lies in robust poultry health management through meticulous vaccination programs, designed to optimize each bird's immunity and resistance to infections. `
      },
      {
        id: 3,
        words: `We provide a tailored vaccination schedule based on your system and disease prevalence, assist with vaccine supply, and offer an organized calendar for seamless execution.`
      },
      {
        id: 4,
        words: `Choose Prunervet Centre, your one-stop hub for veterinary excellence.`
      },
    ]
  }
]

export const Vaccination = [
  {
    id: 1,
    header: "Importance",
    statement: `Vaccination plays an important 
    part in the health management of 
    the poultry flock, by triggering or 
    boosting the chicken immune 
    system to produce antibodies that 
    in turn fight the invading causal 
    organisms`
  },
  {
    id:2,
    header: "Poultry Vaccination Objectives",
    objectives: [
      {
        id: 1,
        statement: "That each bird is to receive a proper dose"
      },
      {
        id: 2,
        statement: " Confer to each bird the ability to resist a particular infection."
      },
      {
        id: 3,
        statement: " Prevention of the disease challenge risk getting into the farm"
      }
    ]
  },
  {
    id : 3,
    header: "Vaccination Program",
    statement : `Based on diseases prevalence and your 
    production system, we build and modify 
    you a vaccination program.`
  },
  {
    id: 4,
    header: "Vaccination Management",
    statement: `Vaccine supply while mapping a calender
    with reminders to aid in effectively 
    executing the program.`
  },
  {
    id: 5,
    header: "Vaccine Administration",
    statement: `We offer vaccine application training and 
    supervisory services.`
  },
  {
    id: 6,
    header: "Vaccination Monitoring",
    statement: `Following vaccination, we can evaluate the efficiency and effectiveness of the vaccination program.`
  }
]

export const our_team = [
  {
    id: "Team",
    catchphrase: "Combining Compassion with Expertise for Superior Care.",
    statement: (
    <>
      Prunervet Centre Limited thrives by employing friendly and knowledgeable personnel 
      which along with our great prices, will drive the repeat business that we rely upon.
      Prunervet Centre is led by a Veterinary Medicine graduate with vast experience in commercial and technical aspects of the veterinary field. 
      Their expertise spans pharmacology, biology, equipment, nutrition, and extension services in Kenya's veterinary industry.
    </>
    )

  }
]

export const Service_Scope = [
  {
    id: "Farm Visits Consultancy",
    purpose: [
      {
        id: "a",
        statement: "To conduct thorough audits of your farm's animal husbandry practices, biosecurity measures, disinfection protocols, and disease management programs"
      },
      {
        id : "b",
        statement: "Identify potential gaps and provide you with actionable recommendations to enhance the overall animal welfare and health management strategies."
      }
    ]
  },
  {
    id: "Biosecurity Audits",
    purpose: [
      {
        id : "a",
        statement : "Review and evaluate the current biosecurity practices on your farm."
      },
      {
        id : "b",
        statement : "Assess the risk factors associated with disease transmission and spread."
      },
      {
        id : "c",
        statement : "Suggest improvements to prevent and control disease outbreaks."
      },
      {
        id : "d",
        statement : "d.	Provide guidance on biosecurity protocols for incoming animals and visitors."
      }
    ]
  },
  {
    id : "Pest and parasite control audits",
    purpose : [
      {
        id : "a",
        statement : "Identification of pest and parasite issues."
      },
      {
        id : "b",
        statement : "Assessment of current control measures."
      },
      {
        id : "c",
        statement : "Risk analysis"
      },
      {
        id : "d",
        statement : "Recommendation for improvement."
      },
    ]
  },
  {
    id : "Vaccination Audits (Poultry)",
    purpose : [
      {
        id : "a",
        statement : "Analyze current vaccination program in place."
      },
      {
        id : "b",
        statement : "Assess the vaccine efficacy and coverage."
      },
      {
        id : "c",
        statement : "Provide guidance on the timing and appropriate vaccines for specific livestock populations."
      },
      {
        id : "d",
        statement : "Develop a customized vaccination schedule for optimal disease prevention."
      },
    ]
  },
  {
    id : "Confidentiality",
    purpose : [
      {
        id : "a",
        statement : `We guarantee utmost confidentiality for all data gathered during farm visits and consultations.
        Our team members will sign Non-Disclosure Agreements to safeguard your farm's sensitive information.`
      }
    ]
  }
]

export const product_details = [
  {
    id : "heading",
    statement : `As a veterinary wholesale and retail pharmacy, we offer a range of products tailored to the needs of livestock farmers, pet owners, and veterinary professionals.`
  },
  {
    id : "Product Services",
    examples : [
      {
        id : "Animal Health Products",
        statement : "We stock a wide variety of livestock and pet health products, classified under Pharm/Drugs, Feeds & Equipment that include but not limited to animal feeds, supplements, equipment,  vaccines, and preventive medications like flea and tick treatments."
      },
      {
        id : "Veterinary Prescription Management",
        statement : "We work closely with veterinary professionals and veterinary clinics to manage prescription orders and ensure seamless communication between the our retail veterinary pharmacy and the veterinarian."
      },
      {
        id : "Product Delivery",
        statement : "We offer home delivery services to livestock and pet owners who may have difficulty visiting the veterinary pharmarcy in person."
      },
      {
        id : "Dispense of veterinary prescriptions",
        statement : "Done for various medications, including antibiotics, anti-parasitics, and more."
      },
      {
        id : "Animal feeds",
        statement : "Supply of specialized animal diets recommended for livestock and pets with specific production and health conditions."
      },
      {
        id : "Feed compounding",
        statement : "Prepare custom formulations of animal feed when standard commercially available options are not suitable for a specific livestock needs."
      },
      {
        id : "Veterinary Medical Supplies",
        statement : "Provide animal equipments and supplies, including feeders, restrainers, syringes, and other items used in livestock production and veterinary care."
      },
      {
        id : "Pet Grooming and Hygiene Products",
        statement : "We offer a selection of grooming and hygiene products."
      },
      {
        id : "Health and Wellness Consultations",
        statement : "We employ knowledgeable staff who can offer advice and guidance on livestock and pet health and wellness, including nutrition, and preventive care."
      },
      {
        id : "Medication Counseling",
        statement : "Educate livestock farmers and pet owners on how to administer medications properly and discuss potential side effects or interactions with other medications."
      },
      {
        id : "Collaboration with veterinary paraprofessionals",
        statement : "Established partnerships with local paravets and offer promotional events or workshops to strengthen ties within the livestock production and pet care community."
      }
    ]
  },
  {
    id : "footer",
    statement : `As a veterinary pharmacy, we have complied with all relevant laws and regulations governing the dispensing of veterinary medications. 
    We have qualified staff trained as veterinary professionals to ensure the safety and well-being of the animals and clients in our care.`
  }
]