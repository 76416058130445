import React from "react";
import { product_details } from "../constants";
import { pvet } from "../assets";
import { Link } from "react-router-dom";

export default function Products () {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col-reverse border-b border-neutral-300">
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src ={pvet} alt="injections" className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]"/>
            </div>
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Our Products</h3>
                <h2 className="font-poppins font-bold md:text-[40px] xs:text-[35px] text-[25px] ">
                    <span className="italic font-semibold md:text-[25px] xs:text-[20px] text-[15px]">Feather to Fur, Field to Home, Hoof to Paw;</span><br />Ensuring Optimal Health for Every Creature in Your Care!
                </h2>
                <div className="bg-green-100 rounded-md p-4">
                {
                    product_details.map((product, index) => (
                        index === 0 || index === 2 && 
                        <div>
                            <h3>{product.statement}</h3>
                        </div>
                    ))
                }
                </div>
                <Link to="/products">
                    <button className="p-3 bg-emerald-500 rounded-md mt-[20px] text-white font-poppins font-semibold md:w-[150px] sm:w-[130px] hover:bg-emerald-600">
                        Our Products
                    </button>
                </Link>
            </div> 

        </div>
    )
}