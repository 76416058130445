import React from "react";
import { newImages } from "../../constants";
import *  as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import { Link } from "react-router-dom";

export default function AboutSection2 (props) {
    React.useEffect(
        () => {
            if (!props.slideDetails.autoSlide) return
            const slideInterval = setInterval(handleNextImage, props.slideDetails.autoSlideInterval)
            return () => clearInterval(slideInterval)
        }, []
    )

    const handlePrevImage = () => {
        props.setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? newImages.length - 2 : prevIndex - 1
        );
      };
    
      const handleNextImage = () => {
        props.setCurrentImageIndex((prevIndex) =>
          prevIndex === newImages.length - 2 ? 0 : prevIndex + 1
        );
      };
    
    return(
        <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6 ">
            <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Gallery</h3>
            <div className="relative md:h-[600px] h-[500px] overflow-hidden mt-4">
                <div className="absolute inset-0 flex md:items-start items-center justify-center">
                    {newImages.map((image, index) => (
                    <div
                        key={image.id}
                        className={`absolute transition-transform ease-out duration-1000 ${
                        index === props.currentImageIndex ? "transform-none opacity-100" : "opacity-0 translate-x-full"}
                        `}
                    >
                        <img src={image.img} alt={`Image ${index + 1}`} className="h-full" />
                    </div>
                    ))}
                </div>
                <div className="absolute inset-0 items-center justify-between p-1 flex">
                    <button className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
                        style={{ top: "50%" }} onClick={handlePrevImage}>
                        <AiIcons.AiOutlineLeft size={40}/>
                    </button>
                    <button className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
                        style={{ top: "50%" }} onClick={handleNextImage}>
                        <AiIcons.AiOutlineRight size={40}/>
                    </button>
                </div>
            </div>
        </div>
    )
}