import React from "react";
import { About } from "../constants";
import { Images } from "../constants";
import { Link } from "react-router-dom";

export default function AboutUs () {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Who we Are</h3>
                <h2 className="font-poppins font-bold md:text-[40px] xs:text-[35px] text-[25px] ">Your Partner in Affordable, Efficient Veterinary Care.</h2>
                <div className="bg-green-100 rounded-md p-4">
                {
                    About.map((about, index) => (
                        about.id === 4 &&
                        <div>
                            <h3>{about.statement}</h3>
                        </div>
                    ))
                }
                </div>

                <Link to="/about" >
                    <button className="p-3 bg-emerald-500 rounded-md mt-[20px] text-white font-poppins font-semibold md:w-[150px] sm:w-[125px] hover:bg-emerald-600">
                        About Us
                    </button>
                </Link>
            </div>
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src = {Images[2].img} alt="hen" className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]" />
            </div>
        </div>
    )
}