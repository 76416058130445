import React from "react";
import { About } from "../../constants";
import { Images } from "../../constants";

export default function AboutSection1() {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300 ">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6 md:-ml-16">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Our Direction</h3>
                <div className="flex flex-col items-center justify-center md:justify-between">
                {
                    About.map((item, index) => (
                        item.id < 3 &&
                        <div className={`mt-4 flex-col flex items-center text-center justify-center bg-green-100 sm:rounded-full rounded-md p-4 w-full object-cover 
                        xl:w-[300px] xl:h-[300px] sm:w-[250px] sm:h-[250px] md:w-[250px] md:h-[250px]`}>
                            <h3 className="font-poppins font-bold md:text-[25px] xs:text-[20px] text-[15px]">{item.header}</h3>
                            <h3>{item.statement}</h3>
                        </div>
                    ))
                }
                </div>
            </div>
            <div className="flex flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6 md:pt-0 pt-4 md:mr-16">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Our Core Values</h3>
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 flex flex-col items-center justify-center">
                {
                    About.map((item, index) => (
                        item.id === 3 &&
                        item.values.map((newItem, index) => (
                            <div className={`mt-4 ${newItem.id % 2 === 0 && "md:ml-[80px]" } flex-col flex items-center text-center justify-center bg-green-100 sm:rounded-full rounded-md p-4 w-full object-cover 
                            xl:w-[300px] xl:h-[300px] sm:w-[250px] sm:h-[250px] md:w-[250px] md:h-[250px]`}>
                                <h3 className="font-poppins font-bold md:text-[25px] xs:text-[20px] text-[15px]">{newItem.value}</h3>
                                <h3>{newItem.aim}</h3>
                            </div>
                        ))
                    ))
                }
                </div>
            </div>
        </div>
    )
}

{/*
                <div className="flex flex-col items-center justify-center">
                {
                    About.map((item, value) => (
                        item.id < 3 &&
                        <div className="mt-4 flex-col flex items-center text-center justify-center bg-green-100 rounded-md p-4 w-full">
                            <h3 className="font-poppins font-semibold md:text-[25px] xs:text-[20px] text-[15px]">{item.header}</h3>
                            <h3>{item.statement}</h3>
                        </div>
                    ))
                }
                </div>
*/}