import React from "react";
import { Vaccination } from "../../constants";
import { Images } from "../../constants";


export default function ServicesSection() {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col-reverse border-b border-neutral-300">
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src = {Images[1].img} alt="hen" className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]" />
            </div>
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Vaccination</h3>
                <div className="bg-green-100 rounded-md p-4">
                {
                    Vaccination.map((item, index) => (
                        index === 0 &&
                        <h3 key = {item.id} >{item.statement}</h3>
                    ))
                }
                </div>
                <div className="flex flex-col items-center justify-center mt-4">
                {
                    Vaccination.map((item, index) => (
                        index === 1 && 
                        <div>
                            <h3 className="font-poppins font-bold md:text-[25px] xs:text-[20px] text-[15px]">{item.header}</h3>
                        </div>
                    ))
                }
                    <div className="flex flex-col items-center justify-center ">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                        {
                            Vaccination.map((item, index) => (
                                index === 1 && 
                                item.objectives.map((item1, index) => (
                                    item.objectives.length % 2 != 0 && index < item.objectives.length - 1 &&
                                    <div className={`mt-4 md:flex-row flex-col flex items-center text-center justify-center bg-green-100 sm:rounded-full rounded-md p-4 w-full object-cover xl:w-[300px] xl:h-[300px] 
                                        sm:w-[250px] sm:h-[250px] md:w-[200px] md:h-[200px]
                                    `}>
                                        <h3>{item1.statement}</h3>
                                    </div>
                                ))
                            ))
                        }
                        </div>
                        <div>
                        {
                            Vaccination.map((item, index) => (
                                index === 1 && 
                                item.objectives.map((item1, index) => (
                                    item.objectives.length % 2 != 0 && index === item.objectives.length - 1 && 
                                    <div className={`mt-4 md:flex-row flex-col flex items-center text-center justify-center bg-green-100 sm:rounded-full rounded-md p-4 w-full object-cover xl:w-[300px] xl:h-[300px]
                                        sm:w-[250px] sm:h-[250px] md:w-[200px] md:h-[200px]
                                    `}>
                                        <h3>{item1.statement}</h3>
                                    </div>
                                ))
                            ))
                        }
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}