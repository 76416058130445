import React from "react";
import { product_details } from "../../constants";

export default function ProductSection1 () {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                {
                    product_details.map((product, index) => (
                        index === 1 && 
                        product.examples.map((example, index) => (
                            <div className={`p-4 ${index != example.length-1 || 2 && "mt-4"} sm:flex flex-col `}>
                                <h3 className="font-poppins font-bold mb-4">{example.id}</h3>
                                <h4 className="bg-green-100 rounded-md p-4 md:h-[120px] sm:h-[250px] flex items-center justify-center">{example.statement}</h4>
                            </div>
                        ))
                    ))
                }
                </div>
            </div>
        </div>
    )
}