import React from "react";
import ServicesSection from "../PageSections/ServicesSection";
import ServicesSection1 from "../PageSections/ServicesSection1";
import ServicesSection2 from "../PageSections/ServicesSection2";

export default function ServicesPage () {
    return (
        <>
            <div className="sm:px-16 px-6 flex justify-center items-start pt-16 md:mt-[20px] mt-[35px]">
                <div className="xl:max-w-[1280px] w-full">
                    <ServicesSection1 />
                </div>
            </div>
            <div className="sm:px-16 px-6 flex justify-center items-start">
                <div className="xl:max-w-[1280px] w-full">
                    <ServicesSection />
                </div>
            </div>
            <div className="sm:px-16 px-6 flex justify-center items-start">
                <div className="xl:max-w-[1280px] w-full">
                    <ServicesSection2 />
                </div>
            </div>
        </>
    )
}