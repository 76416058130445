import React from "react";
import AllImages from "./Images";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Team from "./Team";
import Contact from "./Contact";
import Products from "./Products";

export default function Home(){
    const [slideDetails,setSlideDetails] = React.useState({
        autoSlide: true,
        autoSlideInterval: 4000
    })
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  return (
    <>
        <div className="sm:px-16 px-6 flex justify-center items-start pt-16">
            <div className="xl:max-w-[1280px] w-full "> 
                <AllImages slideDetails = {slideDetails} 
                setDetails = {setSlideDetails}
                currentImageIndex = {currentImageIndex}
                setCurrentImageIndex = {setCurrentImageIndex}
                />
            </div>
        </div>

        <div className="sm:px-16 px-6 flex justify-center items-start">
            <div className="xl:max-w-[1280px] w-full "> 
                <AboutUs />
            </div>
        </div>

        <div className="sm:px-16 px-6 flex justify-center items-start">
            <div className="xl:max-w-[1280px] w-full "> 
                <Products />
            </div>
        </div>

        <div className="sm:px-16 px-6 flex justify-center items-start">
            <div className="xl:max-w-[1280px] w-full "> 
                <Services />
            </div>
        </div>

        <div className="sm:px-16 px-6 flex justify-center items-start">
            <div className="xl:max-w-[1280px] w-full "> 
                <Team />
            </div>
        </div>

        <div className="sm:px-16 px-6 flex justify-center items-start">
            <div className="xl:max-w-[1280px] w-full "> 
                <Contact />
            </div>
        </div>
    </>
  )
}
