import React from "react";
import Navbar from "./components/Navbar";
import { useState } from 'react'
import { animateScroll as scroll, Events } from 'react-scroll'
import AllImages from "./components/Images";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Team from "./components/Team";
import Contact from "./components/Contact";
import Footer from "./components/footer";
import Links from "./components/Links";
import Links2 from "./components/Links2";

export default function App(){
  const [toggle, setToggle] = useState(false)
  const [slideDetails,setSlideDetails] = React.useState({
      autoSlide: true,
      autoSlideInterval: 4000
  })
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  // React.useEffect(() => {
  //   window.scrollTo(0, scrollPosition);
  // }, [scrollPosition]);
  
  let savedPosition = 0
  function newMenu() {
    if (toggle) { // If the menu is currently open
      scroll.scrollTo(savedPosition);// scroll back to the saved position
    } else {
      savedPosition = window.pageYOffset || document.documentElement.scrollTop;
    }
    setToggle((prev) => !prev)
  }

  return (
    <div>
      <Links />
    </div>
  )
}