import React from "react"
import {
    BrowserRouter as Router, //Helps app use react router
    Routes, //Create links and attach them to components
    Route //Attach routes to the components
  } from "react-router-dom"  
import Home from "./Home"
import Navbar from "./Navbar"
import Footer from "./footer"
import JIC from "./jic"
import { useState } from 'react'
import { animateScroll as scroll, Events } from 'react-scroll'
import AboutUsPage from "./Pages/AboutUsPage"
import ScrollToTop from "./scroll"
import ServicesPage from "./Pages/OurServicePage"
import TeamPage from "./Pages/OurTeamPage"
import ContactPage from "./Pages/ContactUsPage"
import ProductsPage from "./Pages/ProductsPage"

export default function Links2() {
    const [toggle, setToggle] = useState(false)

    let savedPosition = 0
    function newMenu() {
      if (toggle) { // If the menu is currently open
        scroll.scrollTo(savedPosition);// scroll back to the saved position
      } else {
        savedPosition = window.pageYOffset || document.documentElement.scrollTop;
      }
      setToggle((prev) => !prev)
    }

    return (
        <Router >
            <ScrollToTop />
            {
            !toggle ?
            <div className="bg-primary w-full ">
                <div className="sm:px-16 px-6 flex justify-center items-center fixed top-0 w-full z-50 bg-white">
                    <div className="xl:max-w-[1280px] w-full">
                        <Navbar newMenu= {newMenu} toggle = {toggle} />
                    </div>
                </div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutUsPage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/products" element={<ProductsPage />}/>
                    <Route path="/team" element={<TeamPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes> 
                <div className="sm:px-16 px-6 flex justify-center items-start">
                    <div className="xl:max-w-[1280px] w-full "> 
                    <Footer />
                    </div>
                </div>
            </div>
            :
            <div className="bg-primary w-full ">
                <div className="sm:px-16 px-6 flex justify-center items-center fixed top-0 w-full z-50 bg-white">
                    <div className="xl:max-w-[1280px] w-full">
                        <Navbar newMenu= {newMenu} toggle = {toggle}/>
                    </div>
                </div>
            </div>
            }
        </Router>
    )
}